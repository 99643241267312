import { Row, Col } from "reactstrap";
import Boxes from "../assets/images/Boxesur.png";
import Table from "../assets/images/tableur.png";
import Google from "../assets/images/Google.svg";
import Apple from "../assets/images/Apple.svg";

const button_1 = () => {
  window.open(
    "https://apps.apple.com/us/app/tajweed-made-easy/id1617022976",
    "_blank"
  ); 
};
const button_2 = () => {
  window.open(
    "https://play.google.com/store/apps/details?id=com.falcon.tajweed",
    "_blank"
  );
};
const JoinSection = () => {
  return (
    <div>
      <div className="box">
        <div className="joinSection_wrapper_main_div">
          <Col lg="6" className="inner_content_wrapper_div_joinSection">
            <h1 className="box-content">
              Join Millions of learners from around the world with your smart
              device to <strong>learn smartly.</strong>
            </h1>
            <div className="joinsection_btns_div">
              <button className="box-buttons" onClick={button_1}>
                <img src={Apple} alt="Apple" />
              </button>
              <button className="box-buttons" onClick={button_2}>
                <img src={Google} alt="Google" />
              </button>
            </div>
            <img src={Table} className="table-image" alt="table" />
          </Col>
          <Col lg="6">
            <img src={Boxes} className="box-image" alt="mobile" />
          </Col>
        </div>
      </div>
    </div>
  );
};

export default JoinSection;
