import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import VocalSection from "../components/VocalSection";
import { useParams } from "react-router-dom";
import NewBlogs from "../components/Blogs/newBlog";
import NewBlogs2 from "../components/Blogs/newBlog2";
import NewBlogs3 from "../components/Blogs/newBlog3";
import NewBlogs4 from "../components/Blogs/newBlog4";
import NewBlogs5 from "../components/Blogs/newBlog5";
import NewBlogs6 from "../components/Blogs/newBlog6";

const BlogDetails = () => {
const { blogId } = useParams();
const blogs = {
  1: <NewBlogs />,
  2: <NewBlogs2 />,
  3: <NewBlogs3 />,
  4: <NewBlogs4 />,
  5: <NewBlogs5 />,
  6: <NewBlogs6 />,
};
console.log(blogId);
return (
  <>
    <Navbar />
    {blogs[blogId]}
    <VocalSection />
    <Footer />
  </>
);
};

export default BlogDetails;
