import React from "react";
import bookImages from "../assets/images/book 1 (1).png";
import bookImages2 from "../assets/images/book 1 (2).png";
import bookImages3 from "../assets/images/book 1 (3).png";
import bookImages4 from "../assets/images/book 1 (4).png";
import bookImages5 from "../assets/images/book 1 (5).png";
import bookImages6 from "../assets/images/book 1 (6).png";
import homefrombg from "../assets/images/homefrom.png";

const booksData = [
  {
    title: "Tajweed Made Easy",
    description:
      "Tajweed Made Easy is our flagship publication based on an efficient and applied methodology in Tajweed..",
    image: bookImages,
    button: "17.00$",
    last: "or <strong>Free Download</strong> in Pdf.",
  },
  {
    title: "آسان تجوید",
    description:
      "آسان تجوید ایک بہترین کتاب ہے، جس میں تجوید سیکھنے اور قرآن کو بہترین پڑھنے کیلئے ایک زبردست طریقہ کار دیا گیا ہے، یہ کتاب ہر عمر کے افراد کیلئے تجوید میں ایک ...مزید دیکھیں      ",
    image: bookImages2,
    button: "20.00$",
  },
  {
    title: "Tajweed Simplifié",
    description:
      "Tajweed Made Easy est notre publication phare basée sur une méthodologie efficace et appl...voir plus...      ",
    image: bookImages3,
    button: "17.00$",
  },
  {
    title: "Упрощённый Таджвид",
    description:
      "Tajweed Made Easy — это наше флагманское издание, основанное на эффективной и прикладной методологии...      ",
    image: bookImages4,
    button: "17.00$",
  },
  {
    title: "Tajweed Mudah",
    description:
      "Tajwid Made Easy ialah penerbitan utama kami berdasarkan metodologi Tajwid yang cekap dan ...lihat lagi...      ",
    image: bookImages5,
    button: "17.00$",
  },
  {
    title: "எளிய�ைறயி� ",
    description:
      "Tajweed Made Easy என்பது Tajweed இல் திறமையான மற்றும் பயன்பாட்டு முறையின் அடிப்படையில் எங்... ",
    image: bookImages6,
    button: "17.00$",
  },
];

const Slider = () => {
  return (
    <div className="books_main_div">
      <h1>Explore our Books in 6 different Languages</h1>
      <div className="books_sub_div">
        {booksData.map((book) => (
          <div key={book.title} className="book_one">
            <button className="price_btn">{book.button}</button>
            <div className="book_img">
              <img src={book.image} alt={book.title} />
            </div>
            <h5>{book.title}</h5>
            <p>{book.description}</p>
            <button>Order Now</button>
            <h6 dangerouslySetInnerHTML={{ __html: book.last }} />
          </div>
        ))}
      </div>
      {/* form home page */}
      <div className="homepage_contact">
        <div className="homepage_contact_cotent">
          <h4>Releasing Soon More Books</h4>
          <h6>
            Stay tuned for an immersive experience that transports you to new
            realms, introduces you to intriguing characters, and sparks your
            imagination. From gripping thrillers to heartwarming tales, our
            forthcoming books are a testament to the diversity and richness of
            storytelling.
          </h6>
          <p>Get More Update </p>
          <div className="homepage_form">
            <input type="text" placeholder="Enter Your Email "></input>
            <button>Subscribe</button>
          </div>
        </div>
        <div className="homeform_bg">
          <img src={homefrombg} alt=".."></img>
        </div>
      </div>
    </div>
  );
};

export default Slider;
