import RightSide from "../assets/images/RightSide.svg";
import mastercard from "../assets/images/mastercard.svg";
import { Row, Col, FormGroup, Form, Input, Label, Button } from "reactstrap";
import { useState } from "react";
import FormTopBar from "../components/FormTopBar";
import { Link } from "react-router-dom";
import OrderDetails from "../components/OrderDetails";
import api from "../api/index";
const Payment = () => {
  const checkout = async () => {
    try {
      let line_items = [
        {
          price_data: {
            currency: "USD", 
            product_data: {
              name: "tajweed/quran", 
            },
            unit_amount: 133 * 100, 
          },
          quantity: 1, 
        },
      ];
      getSessionUrl(line_items);
    } catch (error) {
      console.log(error);
    }
  };
  const getSessionUrl = async (line_items, transferData) => {
    api("post", "/student/checkout", {
      line_items: line_items,
    })
      .then((res) => {
       
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <FormTopBar />

      <div className="container p-lg-4">
        <h3
          style={{
            marginTop: "4%",
            marginBottom: "2%",
            fontFamily: "GothicBold",
          }}
        >
          Payment Details
        </h3>
        <Form>
          <OrderDetails />
          <Button color="success" className="button my-4" onClick={checkout}>
            Pay
          </Button>
        </Form>
      </div>
      <div className="">
        <img src={RightSide} className="r-vector" alt="" />
      </div>
    </>
  );
};

export default Payment;
