import RightSide from "../assets/images/RightSide.svg";
import { toast } from "react-toastify";
import { Row, Col, FormGroup, Form, Input, Label, Button } from "reactstrap";
import { useState } from "react";
import FormTopBar from "../components/FormTopBar";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

const Register = () => {
  let history = useHistory();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    gender: "male",
    age: "",
    city: "",
    state: "",
    country: "",
    email: "",
    password: "",
    whatsapp_number: "",
  });
  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (e) => {
    setUser({ ...user, whatsapp_number: e });
  };
  const handleNext = () => {
    if (user?.firstName === "") {
      return toast.error("First Name is required");
    }
    if (user?.lastName === "") {
      return toast.error("Last Name is required");
    }
    if (user?.age === "" || user?.age < 1) {
      return toast.error("Enter valid Age");
    }
    if (user?.city === "") {
      return toast.error("City is required");
    }

    if (user?.state === "") {
      return toast.error("statee is required");
    }
    if (user?.country === "") {
      return toast.error("country is required");
    }
    if (user?.email === "") {
      return toast.error("email valid Age");
    }
    if (user?.password === "") {
      return toast.error("password is required");
    }
    if (user?.whatsapp_number === "") {
      return toast.error("Whatsapp number is required");
    }

    history.push("/course", { params: user });
  };

  return (
    <>
      <FormTopBar />
      <div className="container p-lg-4">
        <h3
          className="form-head"
          style={{
            marginTop: "4%",
            marginBottom: "2%",
            fontFamily: "GothicBold",
          }}
        >
          Register as a Regular Student
        </h3>
        <Form>
          <Row>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-fname">
                  First Name *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-fname"
                  required={true}
                  type="text"
                  value={user.firstName}
                  name="firstName"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                    marginTop: "4px",
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-lname">
                  Last Name *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-lname"
                  type="text"
                  value={user.lastName}
                  name="lastName"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <FormGroup>
                <Label for="exampleSelect" className="form-control-label">
                  Select Gender *
                </Label>
                <Input
                  id="exampleSelect"
                  name="gender"
                  value={user.gender}
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                  }}
                  className="form-control-alternative"
                  type="select"
                >
                  <option value="male" selected>
                    Male
                  </option>
                  <option value="female" selected>
                    Female
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Age *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-age"
                  type="number"
                  value={user.age}
                  name="age"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  City *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-city"
                  required={true}
                  type="text"
                  value={user.city}
                  name="city"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-state">
                  State/Province *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-state"
                  type="text"
                  value={user.state}
                  name="state"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Country *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-country"
                  type="text"
                  value={user.country}
                  name="country"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-whatsapp_number"
                >
                  WhatsApp Number *
                </label>
                {/* <Input
                  className="form-control-alternative"
                  id="input-whatsapp_number"
                  type="text"
                  value={user.whatsapp_number}
                  name="whatsapp_number"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                /> */}

                <PhoneInput
                  className="form-control-alternative"
                  id="input-whatsapp_number"
                  country={"us"}
                  type="text"
                  value={user.whatsapp_number}
                  name="whatsapp_number"
                  onChange={handlePhoneChange}
                  containerStyle={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                  inputStyle={{
                    border: "none",
                    width: "96%",
                    borderRadius: "27px",
                    marginLeft: "10px",
                    backgroundColor: "white",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Email Address *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-email"
                  type="text"
                  value={user.email}
                  name="email"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-password">
                  Password *
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-password"
                  type="password"
                  value={user.password}
                  name="password"
                  onChange={handleInputChange}
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <Link to="Course"> */}
          <Button color="success" className="button my-4" onClick={handleNext}>
            Next
          </Button>
          {/* </Link> */}
        </Form>
      </div>
      <div>
        <img src={RightSide} className="r-vector" alt="" />
      </div>
    </>
  );
};

export default Register;
