// Init
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// Routes
import Home from "../containers/Home.js";
import Register from "../containers/Register";
import Course from "../containers/Course";
import Payment from "../containers/Payment";
import Login from "../containers/Login";
import Products from "../containers/Products";
import About from "../containers/About";
import ContactUs from "../containers/ContactUs";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import Blog from "../containers/Blog.js";
import BlogDetails from "../components/BlogDetails.js";
import PaymentsAfterLogin from "../containers/PaymentsAfterLogin";
import DeleteAccount from "../containers/DeleteAccount.js";

const index = () => {
  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/course" component={Course} />
      <Route exact path="/payment" component={Payment} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/PaymentsAfterLogin" component={PaymentsAfterLogin} />
      <Route exact path="/Products" component={Products} />
      <Route exact path="/About" component={About} />
      <Route exact path="/ContactUs" component={ContactUs} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/deleteaccount" component={DeleteAccount} />
      <Route exact path="/Blog" component={Blog} />
      <Route exact path="/blog-details/:blogId" component={BlogDetails} />
      <Redirect to="/home" />
    </Switch>
  );
};

export default index;
