import React from "react";
import Navbar from "../components/Navbar";
import DeleteAccountForm from "../components/DeleteAccountForm";
import Footer from "../components/Footer";
import VocalSection from "../components/VocalSection";

const DeleteAccount = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Navbar />
      <DeleteAccountForm />
      <div className="vocalCalledInDeleteAccount">
        <VocalSection />
      </div>
      <Footer />
    </>
  );
};

export default DeleteAccount;
