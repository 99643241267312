import React from "react";
import {
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { Store } from "../StoreContext";
import Logo from "../assets/images/Logo.svg";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = ({ about }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(true);
  const handleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const { user, loggedIn } = Store();
  const handleButtonClick = () => {
    try {
      window.open("http://onelink.to/4rh23t", "_blank");
    } catch (err) {
      console.log(err, "err-------------------->");
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location = "/";
  };
  const currentPath = window.location.pathname
  console.log('pathhhh',currentPath)
  return (
    <div>
      <div className={about ? "navAbout" : "nav"}>
        <nav className="navbar">
          <div className="navbar_wrapper">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="tajweed_logo" />
              </Link>
            </div>
            <div className="navbar_ul">
              <ul id="menu" className="mt-3">
                <li>
                  <NavLink
                    to="/home"
                    className="nav-item"
                    activeClassName="active-nav-item"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/About"
                    className="nav-item"
                    activeClassName="active-nav-item"
                  >
                    About
                  </NavLink>
                </li>
{/* 
                <li>
                  <NavLink
                    to="/Blog"
                    className="nav-item"
                    activeClassName="active-nav-item"
                  >
                    Blog
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/Products"
                    className="nav-item"
                    activeClassName="active-nav-item"
                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/ContactUs"
                    className="nav-item"
                    activeClassName="active-nav-item"
                  >
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </div>
            {loggedIn ? (
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-lg-block">
                      <span
                        className="mb-0 text-sm font-weight-bold"
                        style={{ color: "#47b45f" }}
                      >
                        {user.first_name} {user.last_name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-arrow"
                  style={{ zIndex: "10" }}
                  right
                >
                  <Link to="/Products">
                    <DropdownItem style={{ color: "#47b45f" }}>
                      <span>
                        {user.first_name} {user.last_name}
                        <br />
                        {user.email}
                      </span>
                    </DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout}>
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <div className="btns">
                <Link to="Login">
                  <Button color="outline-success" className="button1">
                    Login
                  </Button>
                </Link>

                <Button
                  color="success"
                  className="button"
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  DOWNLOAD OUR APP
                </Button>
              </div>
            )}
          </div>
          {/* mobile menu */}
          <div className="mobile_navigation">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="tajweed_logo" />
              </Link>
            </div>
          <MenuIcon  onClick={handleMobileMenu}/>
          {mobileMenuOpen && (
            <>
          <div className="navbar_wrapper_mobile">
            <div className="navbar_ul_mobile">
              <ul id="menu_mobile" className="mt-3">
                <li>
                  <NavLink
                    to="/home"
                    className="nav-item_mobile"
                    activeClassName="active-nav-item"
                    style={{color:currentPath === '/home' ? "#47b45f" : "black" }}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/About"
                    className="nav-item_mobile"
                    activeClassName="active-nav-item"
                    style={{color:currentPath ==='/About'? "#47b45f" : "black"}}
                  >
                    About
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/Blog"
                    className="nav-item_mobile"
                    activeClassName="active-nav-item"
                    style={{color:currentPath ==='/Blog'? "#47b45f" : "black"}}

                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Products"
                    className="nav-item_mobile"
                    activeClassName="active-nav-item"
                    style={{color:currentPath ==='/Products'? "#47b45f" : "black"}}

                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/ContactUs"
                    className="nav-item_mobile"
                    activeClassName="active-nav-item"
                    style={{color:currentPath ==='/ContactUs'? "#47b45f" : "black"}}

                  >
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </div>
            {loggedIn ? (
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-lg-block">
                      <span
                        className="mb-0 text-sm font-weight-bold"
                        style={{ color: "#47b45f" }}
                      >
                        {user.first_name} {user.last_name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-arrow"
                  style={{ zIndex: "10" }}
                  right
                >
                  <Link to="/Products">
                    <DropdownItem style={{ color: "#47b45f" }}>
                      <span>
                        {user.first_name} {user.last_name}
                        <br />
                        {user.email}
                      </span>
                    </DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout}>
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <div className="btns_mobile">
                <Link to="Login">
                  <Button color="outline-success" className="button1">
                    Login
                  </Button>
                </Link>

                <Button
                  color="success"
                  className="button"
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  DOWNLOAD OUR APP
                </Button>
              </div>
            )}
          </div>
          {/* end */}
          </>

          )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
