import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 5000,
    centerMode: true, 
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  

  return (
    <div className="w-3/4 m-auto review_div">
      <h1>Testimonials</h1>
      <div className="mt-20">
        <Slider {...settings}>
          {data?.map((d, index) => (
            <div key={d.name}>
              <div
                className="flex flex-col items-center justify-center gap-4 p-4 review_1"
                id="custom-card"
              >
                <p className="text-center">{d?.review}</p>
                <h5 className="text-xl font-semibold ">{d?.name}</h5>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

const data = [
  {
    name: `Abubakar Rehman`,
    review: `Tajweed Made Easy transformed my Quranic recitation journey! The
                     simple explanations and interactive lessons made learning
                    Tajweed a breeze. A must-have for beginners like me!`,
  },
  {
    name: `Ayesha Shoukat`,
    review: ` Finally, a user-friendly Tajweed guide! This resource breaks
                    down complex rules into digestible lessons. The interactive
                    exercises are a game-changer. Highly recommend!`,
  },
  {
    name: `Atiya Sher`,
    review: `Tajweed Made Easy is a gem for anyone striving to perfect their
                    recitation. The engaging lessons and practice sessions kept me
                    motivated. My Tajweed has never been better`,
  },
  {
    name: `Waleed Naeem`,
    img: `/students/Rigo_Louie.jpg`,
    review: `I was intimidated by Tajweed, but this resource made it
                   accessible. The simplified explanations and practical exercises
                   built my confidence. It's an invaluable tool for learners at any
                   level!`,
  },
  {
    name: `Afaq Azhar`,
    img: `/students/Mia_Williams.jpg`,
    review: ` With its user-friendly approach, Tajweed Made Easy not only
                     simplifies the intricacies of Tajweed but also empowers learners
                     to master the art of Quranic recitation seamlessly.`,
  },
];

export default App;
