import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import VocalSection from "../components/VocalSection";
import about from "../assets/images/about.svg";
import book1 from "../assets/images/image 1.png";
import bookImages2 from "../assets/images/image2.png";
import bookImages3 from "../assets/images/image3.png";
import bookImages4 from "../assets/images/image4.png";
import bookImages5 from "../assets/images/image5.png";
import missionQuran from "../assets/images/missionQuran.png";
import founder1 from "../assets/images/Rectangle (1).png";
import founder2 from "../assets/images/Rectangle.png";
import founder3 from "../assets/images/Picture (69).png";
import partners from "../assets/images/Brand (1).png";

const About = () => {
  const aboutbooks = [
    {
      id: 1,
      title: "Tajweed Made Easy",
      description:
        "Tajweed Made Easy is our flagship publication based on an efficient and applied methodology in Tajweed It provides a simple...        ",
      imageSrc: book1,
    },
    {
      id: 1,
      title: "آسان تجوید",
      description:
        "آسان تجوید ایک بہترین کتاب ہے، جس میں تجوید سیکھنے اور قرآن کو بہترین پڑھنے کیلئے ایک زبردست طریقہ کار دیا گیا ہے، یہ کتاب ہر عمر کے افراد کیلئے تجوید میں ایک ...مزید دیکھیں        ",
      imageSrc: bookImages2,
    },
    {
      id: 1,
      title: "Tajweed Simplifié",
      description:
        "Tajweed Made Easy est notre publication phare basée sur une méthodologie efficace et appl...voir plus...        ",
      imageSrc: bookImages3,
    },
    {
      id: 1,

      title: "Упрощённый Таджвид",
      description:
        "Tajweed Made Easy — это наше флагманское издание, основанное на эффективной и прикладной методологии узнать больше...        ",
      imageSrc: bookImages4,
    },
    {
      id: 1,
      title: "Tajweed Mudah",
      description:
        "Tajwid Made Easy ialah penerbitan utama kami berdasarkan metodologi Tajwid yang cekap dan lihat lagi...        ",
      imageSrc: bookImages5,
    },
    {
      id: 1,
      title: "எளிய�ைறயி�",
      description:
        "Tajweed Made Easy என்பது Tajweed இல் திறமையான மற்றும் பயன்பாட்டு முறையின் அடிப்படையில் எங் மேலும் பார்க்க...        ",
      imageSrc: bookImages4,
    },
  ];
  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar about={true} />
      <div className="hero_section_wrapper_about">
        <div className="firstCol">
          <h1 className="aboutHead"><span>About </span>Tajweed.</h1>
          <p>
            At Tajweed Made Easy, Or goal is to make Quran learning and
            recitation easy for the beginners and for new Muslims and Adilts
            Our focus is to spread the knoledge of Tajweed starting from
            makharij and convering all bacis rules of Tajweed and enabling our
            subdents to read Quran with proper Tajweed and fluency
            </p>
          <button>Get Started</button>
        </div>
        <div className="hero_section_image">
          <img src={about} className="aboutImg" alt="vector" />
        </div>
      </div>
      {/* ABOUT PAGE BOOKS SECTION */}
      <div className="about_books_section">
        <div className="about_book_main_div">
          <div className="book_section_left">
            <h2>Download our Book in different Languages</h2>
            <p>
              Navigate through our user-friendly platform, where downloading
              books in your preferred language is just a click away. Join us on
              a literary journey that celebrates diversity, promotes cultural
              understanding, and invites you to savor the written word in the
              language that speaks to your heart.
            </p>
            <button>Download Now</button>
          </div>
          <div className="book_section_right">
            {aboutbooks.map((book) => (
              <div key={book.id} className="about_book1">
                <div className="about_book1_img_div">
                  <img src={book.imageSrc} alt={book.title} />
                </div>
                <h3>{book.title}</h3>
                <p>{book.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* OUR MISSION & VISION */}
      <div className="mission_main_div">
        <div className="mission_sub_div">
          <div className="mission_left_div">
            <div className="mission_img_div">
              <img src={missionQuran} alt="."></img>
            </div>
          </div>
          <div className="mission_right_div">
            <h2>
              <span>Our</span> Mission & Vision
            </h2>
            <p>
              At the heart of our dedication to Tajweed lies a profound mission
              and vision. Our mission is to empower individuals on their
              spiritual journey by providing accessible and comprehensive
              resources for mastering the art of Tajweed. We are committed to
              preserving the beauty of Quranic recitation, ensuring that
              learners, regardless of their background, can connect with the
              sacred text in a meaningful way. Our vision extends beyond mere
              instruction; we envision a community where Tajweed becomes an
              integral part of spiritual growth, fostering a deep understanding
              and appreciation for the nuances of Quranic pronunciation. Join us
              in this pursuit, where our mission and vision converge to create a
              harmonious space for the mastery and reverence of Tajweed.
            </p>
            <button> Get Started</button>
          </div>
        </div>
      </div>
      {/* OUR FOUNDER */}
      <div className="founder_main_div">
        <div className="founder_sub_div">
          <h1>
            Meet Our <span>Founders</span>
          </h1>
          <p>
            Discover the brilliance behind Tajweed Made Easy as you meet our
            founders—innovative minds who forged a vision into reality, igniting
            a legacy of inspiration and impact.
          </p>
          <div className="about_founders">
            <div className="founder_info">
              <div className="founder_img_div">
                <img src={founder1} alt="."></img>
              </div>
              <h4>Umar Farooq</h4>
              <h6>CEO & Founder</h6>
            </div>
            <div className="founder_info">
              <div className="founder_img_div">
                <img src={founder2} alt="."></img>
              </div>
              <h4>Umar Farooq</h4>
              <h6>CEO & Founder</h6>
            </div>
            <div className="founder_info">
              <div className="founder_img_div">
                <img src={founder3} alt="."></img>
              </div>
              <h4>Umar Farooq</h4>
              <h6>CEO & Founder</h6>
            </div>
          </div>
        </div>
      </div>
      {/* MESSAGE CEO */}
      <div className="message_ceo_main_div">
        <div className="message_ceo_sub_div">
          <div className="message_ceo_left_div">
            <h1>Message Form CEO </h1>
            <p>- Umer Farooq </p>
          </div>
          <div className="message_ceo_left_div">
              {/* <span>“</span> */}
            <h5>
              {/* <br></br> */}
              “ Welcome to Tajweed Made Easy, where our commitment to excellence
              is not just a goal but a guiding principle. As CEO, I am honored
              to lead a team dedicated to [briefly mention the organization's
              primary mission or purpose]. Together, we navigate a path of
              innovation, collaboration, and unwavering dedication to making a
              meaningful impact. Thank you for being a part of our journey as we
              continue to strive for excellence and positive change ”
              <br></br>
            </h5>
              {/* <span className="bottom_span">”</span> */}
          </div>
        </div>
      </div>
      {/* Tajweed partner */}
      <div className="partner_main_div">
        <div className="partner_sub_div">
          <div className="partner_left_div">
            <h1>
              <span>Tajweed </span>Partners{" "}
            </h1>
            <p>
              Elevating the standards of Tajweed education, our Tajweed Partners
              play a pivotal role in enriching our resources and expertise.
              Together, we forge a path dedicated to accessible and high-quality
              Quranic recitation, united by a shared commitment to fostering a
              profound connection with the divine.
            </p>
          </div>
          <div className="partner_right_div">
            <div className="parter_logo_list">
              <img src={partners} alt="."></img>
            </div>
          </div>
        </div>
      </div>
      {/* location map */}
      <div className="mapBox">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6807.880480041278!2d74.2714969!3d31.443312099999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1658227823231!5m2!1sen!2s"></iframe>
      </div>

      <VocalSection />
      <Footer />
    </div>
  );
};

export default About;
