import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Table from "../assets/images/contactfrombg.png";
import Icon from "../assets/images/contact@.svg";
import Email from "../assets/images/Email Us.png";
import VocalSection from "../components/VocalSection";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { FormGroup, Form, Input, Button } from "reactstrap";
import api from "../api/index";
import { toast } from "react-toastify";

const ContactUs = () => {
  // const onChange = () => {};
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const handleRecaptchaChange = (value) => {
    // value will be null if the reCAPTCHA challenge is not solved
    setIsRecaptchaChecked(value !== null);
  };

  const [user, setUser] = useState({
    email: "",
    name: "",
    message: "",
  });

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    if (isRecaptchaChecked) {
      // Perform your form submission logic here
      toast.success("Form submitted successfully!");
    } else {
      // Display an error message or take appropriate action
      toast.error("Please complete the ReCAPTCHA");
    }
    e.preventDefault();

    await api("post", "/contacts", user);
    setUser({
      email: "",
      name: "",
      message: "",
    });

    if (isRecaptchaChecked) {
      // Your form submission logic here
      console.log("Form submitted successfully!");
    } else {
      // Display an error message or handle the lack of reCAPTCHA verification
      console.error("Please complete the reCAPTCHA challenge.");
    }
  };

  return (
    <>
      <Navbar about={false} />
      <div>
        <div className="contactBox">
          <div className="contact_form_main_div">
            <div className="contact_page_left_div">
              <h1 className="boxContent">Get a quote</h1>
              <p className="contact-text">
                Fill up the form and our Team will get back to you within 24
                hours.
              </p>
              <div className="info">
                <div style={{ marginBottom: "3%" }}>
                  <img src={Icon} alt="phone" /> &nbsp; 042 2233445
                </div>
                <div>
                  <img src={Email} alt="email" /> &nbsp;contact@tajweed.com
                </div>
              </div>
              <div className="recapta_main_div">
                <ReCAPTCHA
                  sitekey="6LdYwD0pAAAAAPrgUw5WxzvggNSIYJjZK3qvo3MR"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <img
                src={Table}
                className="table-image"
                style={{
                  position: "absolute",
                  left: "0",
                  bottom: "0",
                  top: "auto",
                }}
                alt="table"
              />
            </div>

            <div className="Contact_form_content">
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Full Name *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    placeholder="Your Full Name"
                    type="text"
                    value={user.name}
                    name="name"
                    onChange={handleInputChange}
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #969696",
                      width: "100%",
                    }}
                  />
                </FormGroup>{" "}
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email Address *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    placeholder="Abubakar@tajweed.com"
                    type="text"
                    value={user.email}
                    name="email"
                    onChange={handleInputChange}
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #969696",
                      width: "100%",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    Message *
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    type="textarea"
                    value={user.message}
                    name="message"
                    onChange={handleInputChange}
                    placeholder="Type Something"
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #96969",
                      width: "100%",
                      paddingBottom: "7%",
                    }}
                  />
                </FormGroup>
                <Button
                  color="success"
                  className="button my-4"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Send Message
                </Button>
                <div className="recapta_main_div_bottom">
                  <ReCAPTCHA
                    sitekey="6LdYwD0pAAAAAPrgUw5WxzvggNSIYJjZK3qvo3MR"
                    // onChange={onChange}
                    // sitekey="your-recaptcha-site-key"
                    onChange={handleRecaptchaChange}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <VocalSection />
      <Footer />
    </>
  );
};

export default ContactUs;
