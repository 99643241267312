import React from "react";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import blogown from "../../assets/images/Picture (70).png";
import blogown3 from "../../assets/images/image 17 (3).png";
import blogimg from "../../assets/images/image 17 (7).png";
import blogown1 from "../../assets/images/image 17 (1).png";
import blogown2 from "../../assets/images/image 17 (2).png";
import blogdetailsimg2 from "../../assets/images/image 18.png";

const blogs = [
  {
    blogimg: blogown3,
    blogown: blogown,
    bloggerName: "Abubakar",
    date: "November 20, 2023",
    title: "Hanbali Ruling on a Muslim Who Does Not Pray",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text Lorem Ipsum is simply",
    readTime: "2 min 5 sec read",
  },

  {
    blogimg: blogown1,
    blogown: blogown,
    bloggerName: "Abubakar",
    date: "November 20, 2023",
    title: "Hanbali Ruling on a Muslim Who Does Not Pray",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text Lorem Ipsum is simply",
    readTime: "2 min 5 sec read",
  },

  {
    blogimg: blogown2,
    blogown: blogown,
    bloggerName: "Abubakar",
    date: "November 20, 2023",
    title: "Hanbali Ruling on a Muslim Who Does Not Pray",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text Lorem Ipsum is simply",
    readTime: "2 min 5 sec read",
  },
];

const BlogDetails = () => {
  return (
    <>
      <div className="blogdetails_main_div">
        <div className="blogdetails_sub_div">
          <div className="blogdetails_content_div">
            <h6>November 20,2023</h6>
            <h3>Hanbali Ruling on a Muslim Who Does Not Pray , blog 1</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.Lorem Ipsum is simply dummy text Lorem Ipsum is simply
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </p>
            <div className="blogdetails_blogger_info_main_div">
              <div className="blogdetails_blogger_info">
                <img src={blogown} alt="." />
                <p>Abubakar</p>
              </div>
              <div className="blogdetails_icon_div">
                <div className="blogdetails_icon_1">
                  <TimerOutlinedIcon style={{ color: "#47B45F" }} />
                  <h5>2 min 5 sec read</h5>
                </div>
              </div>
            </div>
            <div className="blog_details_img_div">
              <img src={blogimg} alt="."></img>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>Introduction</h4>
              <p>
                n Islamic jurisprudence, the performance of the five daily
                prayers is considered a fundamental pillar of the faith. The
                Hanbali school of thought, one of the four major Sunni schools
                of Islamic law, provides specific rulings regarding the
                obligation of prayer and the consequences for those who neglect
                this vital act of worship.
              </p>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>The Importance of Prayer in Islam</h4>
              <p>
                Before delving into the Hanbali perspective, it's crucial to
                highlight the significance of prayer in Islam. Prayer, or Salah,
                is a direct connection between the believer and Allah. It serves
                as a manifestation of faith, a means of seeking forgiveness, and
                a reminder of one's duties to the Creator.
              </p>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>Hanbali Ruling on Abandoning Prayer</h4>
              <p>
                According to the Hanbali school, neglecting the obligatory
                prayers without a valid excuse is considered a grave sin.
                Scholars within the Hanbali tradition derive their rulings from
                the Quran, Hadith (sayings and actions of Prophet Muhammad), and
                the consensus of the scholars.
              </p>
            </div>
            <div className="blog_details_img_div">
              <img src={blogdetailsimg2} alt="."></img>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>The Consequences</h4>
              <p>
                Hanbali scholars emphasize that abandoning prayer intentionally
                is a major sin, and those who persist in this neglect may face
                severe consequences. These consequences include the potential
                denial of intercession on the Day of Judgment, the nullification
                of certain rights over wealth and marriage, and being
                categorized among those who have abandoned their faith.
              </p>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>Repentance and Redemption</h4>
              <p>
                Despite the severity of the consequences, the Hanbali school,
                like other Sunni schools, acknowledges the mercy of Allah.
                Repentance (Tawbah) is seen as a means of redemption. If an
                individual sincerely repents, seeks forgiveness, and returns to
                regular prayer, they may be absolved of the sin.
              </p>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>The Role of Education and Support</h4>
              <p>
                Understanding the Hanbali perspective on neglecting prayer
                emphasizes the importance of community support and education.
                Muslims should be aware of the significance of prayer and the
                consequences of abandoning it. Communities and scholars play a
                pivotal role in fostering an environment that encourages regular
                prayer and provides resources for those struggling with
                consistency.
              </p>
            </div>
            <div className="blog_details_bottom_content_div">
              <h4>Conclusion</h4>
              <p>
                In the Hanbali school of thought, neglecting the obligatory
                prayers is viewed as a serious transgression, but it is not
                devoid of hope. Repentance and a return to regular prayer can
                serve as a path to redemption. It is crucial for individuals,
                communities, and scholars to work collectively in promoting
                awareness, understanding, and a supportive environment to help
                Muslims fulfill this fundamental aspect of their faith.
              </p>
            </div>
            <h3>Related Articles </h3>
            <div>
              <div className="blog_content_div blog_details_content">
                <div className="blog_conten_wrapper">
                  {blogs.map((blog, index) => (
                    <div key={index} className="blog_1">
                      <div className="blog_1_img">
                        <img src={blog.blogimg} alt="." />
                        <div className="blogger_info">
                          <img src={blog.blogown} alt="." />
                          <p>{blog.bloggerName}</p>
                        </div>
                      </div>
                      <p className="blog_date">{blog.date}</p>
                      <h4>{blog.title}</h4>
                      <p className="blog_1_p">{blog.content}</p>
                      <div className="blog_icon_div">
                        <div className="icon_1">
                          <TimerOutlinedIcon />
                          <p>{blog.readTime}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
