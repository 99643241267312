import React, { useState } from "react";
import Qrcode from "../assets/images/qr.svg";
import twitter from "../assets/images/twitter.svg";
import facebook from "../assets/images/facebook11 (1).svg";
import insta from "../assets/images/insta11 (2).svg";
import mobileimg from "../assets/images/iPhone 15 Pro Max.png";

import mobileimg1 from "../assets/images/iphonered.png";
import mobileimg2 from "../assets/images/iphonegreen.png";
import mobileimg3 from "../assets/images/iphonegray.png";
import colorarrow from "../assets/images/Polygon 1.png";
import textarrow from "../assets/images/textPolygon 1 (1).png";

const Header = () => {
  const [selectedButton, setSelectedButton] = useState(1);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);
  };

  const getImageSource = () => {
    switch (selectedButton) {
      case 1:
        return mobileimg;
      case 2:
        return mobileimg1;
      case 3:
        return mobileimg2;
      case 4:
        return mobileimg3;
      default:
        return mobileimg1;
    }
  };

  return (
    <div className="header_main_div">
      <div className="header_sub_div">
        <div className="header_left_div">
          <h1>
            Explore the new Unique & Institutive way to learn
            <span>Tajweed.</span>
          </h1>
          <p>
            Embark on a transformative journey as we present a revolutionary
            approach to mastering Tajweed, the art of Quranic recitation. Our
            project, "Tajweed Made Easy," introduces a unique and intuitive
            method that redefines the way you learn the intricate rules of
            Tajweed.
          </p>
          <button>Get Started</button>
          <div className="qr_scan_main_div">
            <div className="qr_scan_img_div">
              <img src={Qrcode} alt="." />
            </div>
            <h3>Scan This QR Code and Get the App</h3>
          </div>
        </div>
        {/* header right div */}
        <div className="header_right_div">
          {/* header_right_left_div */}
          <div className="header_right_left-div">
            <div className="textarrow_img">
              <img src={textarrow} alt="." />
            </div>
            <div className="header_icon_main_div">
              <img src={twitter} alt="." />
              <p>Twitter</p>
            </div>
            <div className="header_icon_main_div">
              <img src={insta} alt="." />
              <p>Instagram</p>
            </div>
            <div className="header_icon_main_div">
              <img src={facebook} alt="." />
              <p>Facebook</p>
            </div>
          </div>
          {/* header_right_center_div */}
          <div className="header_right_center-div">
            <div className="mobile_img">
              <img src={getImageSource()} alt="." />
            </div>
          </div>
          {/* header_right_right_div */}
          <div className="header_right_right-div">
            <div className="colorarrow_img">
              
              <img src={colorarrow} alt="."></img>
            </div>
            <button
              className={`header_button ${
                selectedButton === 1 ? "selected" : ""
              }`}
              onClick={() => handleButtonClick(1)}
            ></button>
            <button
              className={`header_button header_button_1 ${
                selectedButton === 2 ? "selected" : ""
              }`}
              onClick={() => handleButtonClick(2)}
            ></button>
            <button
              className={`header_button header_button_2 ${
                selectedButton === 3 ? "selected" : ""
              }`}
              onClick={() => handleButtonClick(3)}
            ></button>
            <button
              className={`header_button header_button_3 ${
                selectedButton === 3 ? "selected" : ""
              }`}
              onClick={() => handleButtonClick(4)}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
