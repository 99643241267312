import React, { useState } from "react";
import MobileFormImg from "../assets/images/DeleteAccount/tajweebMobileBg.png";
import callIcon from "../assets/images/DeleteAccount/tajweedCallIcon.svg";
import MessageIcon from "../assets/images/DeleteAccount/tajeedMsgIcon.svg";
import LocationIcon from "../assets/images/DeleteAccount/tajweedLocationIcon.svg";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const contactInfo = [
  {
    id: 1,
    icon: callIcon,
    title: "Call Us",
    phoneNumber: "12345678765",
  },
  {
    id: 1,
    icon: MessageIcon,
    title: "Gmail",
    phoneNumber: "xyz@tajwee.com",
  },
  {
    id: 1,
    icon: LocationIcon,
    title: "Address",
    phoneNumber: "11 rue Marie de Médicis",
  },
  // Add more objects as needed
];

const DeleteAccountForm = () => {
  let history = useHistory();
  const [email, setEmail] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    // Perform any other necessary actions before navigating or showing success toast

    toast.success("We'll contact you soon");
    history.push("/");
  };

  return (
    <>
      <div className="deleteAccouuntMainDiv">
        <div className="deleteAccountSubDiv">
          <img src={MobileFormImg} alt="mobileImg" />
          <div className="innerForm">
            <h1>Delete Account</h1>
            <p>Are you sure you want to delete Your account</p>
            <form className="formStart" onSubmit={handleSubmit}>
              <div className="deleteAccountInputDiv">
                <label> Email</label> <br />
                <input
                  type="email"
                  placeholder=" Abubakar@tajweed.com"
                  name="email"
                  autoComplete="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <hr style={{ color: "white", margin: 0, width: "95%" }} />
              </div>
              <button type="submit">Delete</button>
            </form>
          </div>
        </div>
      </div>
      <div className="deleteAccountAddressDiv">
        <div className="deleteAccountAddressSubDiv">
          {contactInfo.slice(0, 3).map((info) => (
            <div className="deleteAccountIcons" key={info.id}>
              <img src={info.icon} alt={info.title}></img>
              <h4>{info.title}</h4>
              <h5>{info.phoneNumber}</h5>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DeleteAccountForm;
